import React from "react"
import SEO from "../components/seo"
import "../styles/theme.scss"
import Logo from "../images/logo.kdc.svg"
import Layout from "../layouts/layout";
import { graphql } from "gatsby"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const NotFound = ({ data, location }) => {
    let global = data.contentfulWebsite;
    return (
        <Layout pathname={location.pathname}>
            <SEO metaTitle={global.title} metaDescription={global.description} author={global.author} shareImage={global.shareImage.file.url} />
            <div className="o-wrapper o-content o-container">
                <div className="o-content-container">
                    <h1>
                        <img className="c-logo" src={Logo} alt={global.title}></img>
                    </h1>
                    <h2 className="u-text-center c-tagline">{global.tagline}</h2>
                </div>
                <div className="u-text-center u-text-large o-content-spacer o-content-spacer--large">
                    {documentToReactComponents(JSON.parse(global.pageNotFoundMessage.raw))}
                </div>
            </div>
        </Layout>
    );
};

export default NotFound

export const pageQuery = graphql`
{
    contentfulWebsite(title: {eq: "Kneading Dough Canada"}) {
        author
        description
        title
        tagline
        pageNotFoundMessage {
            raw
        }
        shareImage {
            file {
                url
            }
        }
    }
}
`;
